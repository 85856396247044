.btn.btn-outline-primary.dropdown-toggle::after {
  margin-left: 0;
}

@media (max-width: 992px) {
  th.thr-control {
    min-width: 60px;
  }

  td.dtr-control div.dynamicControl.showButton {
    display: flex;
    margin-left: 4px;
  }
}

td.dtr-control div.dynamicControl {
  padding: 2px 2px;
  outline: 0;
  margin-top: 6px;
  border: 0;
  background-color: #0d85f6;
  color: white;
  border-radius: 50%;
  width: 17.2px;
  height: 17.2px;
  font-size: 18px;
  cursor: pointer;
  display: none;
  justify-content: center;
  align-items: center;

  box-shadow: 0 0 0.2em #444;

  border: 0.15em solid white;
}

td.dtr-control div.dynamicControl.hide {
  background-color: red;
  font-weight: bold;
}

.statusWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Align items to the start of the column */
  margin-bottom: 10px; /* Add some spacing between items if needed */
}


