#profile-1 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.btn-info {
  background-color: #17a2b8 !important;
  color: white !important;
}

.btn-info:hover {
  background-color: #138496 !important;
  color: white !important;
}

button.form-control.btn.login-button-color-1.btn-block {
  color: white;
}

.card-body > .table > thead > tr > th {
  border-top-width: 0;
  width: 1px;
}

.changepasswordsize {
  width: 100%;
}
.passwordchangecardsize {
  left: 30%;
  width: 350px;
}

.text-in-change-password {
  width: 100%;
  line-height: 1.6;
  margin-bottom: 5px;
  font-size: 1rem;
}

p.text-in-change-password.lower-low {
  width: 90%;
  text-align: center;
  margin-bottom: 10px;
}

@media only screen and (max-width: 990px) {
  .passwordchangecardsize {
    width: 70%;
    left: 15%;
  }
}
