.virtual-teen-pati {
  align-items: flex-end;
}

.h5-color {
  color: black;
  font-weight: bold;
}

.virtual-teen-pati .score-panel {
  position: relative;
  height: 210px;
}

.virtual-teen-pati .score-panel .card-panel {
  position: absolute;
  width: 100%;
  height: 80%;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 8px;
}

.virtual-teen-pati .score-panel .card-panel .card-header {
  color: #fff;
  font-size: 16px;
  font-weight: 600;
}

.virtual-teen-pati .score-panel .bet-timer {
  position: absolute;
  width: 100%;
  height: 20%;
  top: 80%;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
}

.virtual-teen-pati .score-panel .card-panel.card-panel-ab {
  flex-direction: row;
  justify-content: start;
  align-items: center;
  height: 120px;
}

.card-panel .card-header-container {
  height: 100%;
  justify-content: space-between;
}

.virtual-teen-pati .score-panel .card-panel .card-header.card-header-ab {
  line-height: 16px;
}

@media screen and (max-width: 768px) {
  .top-row .col-md-3 {
    margin-top: 12px;
  }

  .top-row .col-md-3.last-row {
    margin-bottom: 12px;
  }
}
