li.match-session-check {
  min-height: 65px;
}

/* input.session-bet-check {
  left: 35px;
  border: 1px solid;
} */

.long-table-11 {
  overflow-x: auto;
}

.inplay-match-bet-table {
  width: 100%;
}

.card-header.card-header-top-line {
  background: #6c757d !important;
}

.card.col-2.mb-4.text-md.text-secondary.betopen {
  width: 70px;
  height: 70px;
  background-color: rgb(2, 97, 164);
  text-align: center;
  right: 20px;
  position: absolute;
  margin-left: 1px;
}

.card.col-2.mb-4.text-md.text-secondary.betopen h5 {
  color: white;
  margin-top: 20%;
  font-weight: bold;
  font-size: 17.81px !important;
}
