* {
  font-family: "Source Sans Pro", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol";
}

.card-title {
  color: black;
}

.form-row {
  width: 80% !important;
}

.row {
  padding-top: 0.25rem;
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}

#datatable {
  border-collapse: collapse; /* Collapse borders into a single border */
  width: 100%; /* Ensure table takes full width */
}

#datatable th,
#datatable td {
  border: 1px solid #ddd; /* Add 1px solid border around each cell */
  padding: 8px; /* Add padding inside cells for spacing */
}

#datatable th {
  background-color: transparent; /* Optional: Add background color to header cells */
  font-weight: bold; /* Optional: Make header text bold */
}

#datatable {
  border-collapse: collapse;
  overflow: auto;
  /* Hide the scrollbar */
}

#datatable {
  clear: both;
  margin-top: 6px !important;
  margin-bottom: 6px !important;
  max-width: none !important;
  border-spacing: 0;
}

#row1 {
  align-items: normal !important;
}

.text-in-change-password {
  width: 50%;
  line-height: 1.5;

  margin: 0 auto;
}

.lower-low {
  margin-top: 10px;
}
