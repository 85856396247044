.card-header.bg-purple .container-fluid .row {
  align-items: flex-end;
}

div.form-group label {
  margin-left: 0;
}

#selectordatareport {
  color: black !important;
}
