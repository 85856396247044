.user-footer .btn.btn-default.btn-flat {
  border-radius: 10px;
  border-width: 1px;
  box-shadow: none;
}
.user-footer .btn.btn-default {
  background-color: #f8f9fa;
  border-color: #ddd;
}

.dropdown-menu.dropdown-menu-lg.dropdown-menu-right {
  margin-right: 1px; /* Adjust the value as needed */
}

.icon-size-header {
  padding-left: 15px;
}

.user-pic-code-size {
  margin-right: 15px;
}

.navbar-nav .nav-item .nav-link {
  padding: 5px 10px; /* Adjust the padding as needed */
  font-size: 14px; /* Adjust the font size as needed */
}

.navbar-nav .user-menu .dropdown-menu {
  top: 35px; /* Adjust the top position of the dropdown menu as needed */
}

@keyframes sidebarAnimation {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}

.navbar-nav {
  animation: sidebarAnimation 0.5s ease-in-out;
}

@media (max-width: 992px) {
  .sidebar-open nav.main-header,
  .sidebar-open nav.main-header::before {
    margin-left: 250px !important;
  }
}
