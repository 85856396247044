td.action-btn-container {
  vertical-align: middle;
}

.action-btn-container .action-btns {
  font-size: 0.75rem;
  line-height: 1.25;
  padding: 6px 10px;
  border-radius: 0.25rem;
}
