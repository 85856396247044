body {
  font-family: "Source Sans Pro", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol" !important;
  font-size: 0.875rem !important;
  font-weight: 400 !important;
  line-height: 1 !important;
  color: #212529 !important;
}

.nav-icon {
  color: black;
}

/* For larger screens */

/* For larger screens */
#brand-text1 {
  font-weight: 300;
  font-size: 16px;
  height: 50% !important;
}

.brand-image {
  width: 25px;
  height: 25px;
  margin-top: 3px !important;
}
