td.action-btn-container {
  vertical-align: middle;
}

.action-btn-container .action-btns {
  font-size: 0.75rem;
  line-height: 1.25;
  padding: 6px 10px;
  border-radius: 0.25rem;
}

.agent-master-child-limit-color {
  line-height: 30px !important;
  color: black;
}

.child-limit-input-size {
  min-width: 140px !important;
}

td.action-btn-container {
  min-width: 180px !important;
}

.client-name-1 {
  min-width: 95px;
}

.client-name-2 {
  min-width: 95px;
}

.client-name-3 {
  min-width: 130px;
  line-height: 20px;
}

.action-button-plus-minus {
  padding: 0.5rem 1rem !important;
  font-size: 0.825rem !important;
  box-shadow: none !important;
}

.column-parent-1 .col-6 select {
  margin-top: -10px;
}

@media screen and (max-width: 768px) {
  .card-body .row .column-parent-1 .parent-container {
    align-items: normal;
    flex-direction: column;
  }

  .column-parent-1 .input-container {
    margin-left: -130px !important;
  }

  .column-parent-1 .col-6 {
    left: 22%;
    margin-bottom: -10px;
  }

  .column-parent-1 .col-6 select {
    margin-top: -10px;
  }
}

@media screen and (max-width: 610px) {
  .card-body .row .column-parent-1 .parent-container {
    align-items: normal;
    flex-direction: column;
  }

  .column-parent-1 .input-container {
    margin-left: -130px !important;
  }

  .column-parent-1 .col-6 {
    left: 10%;
    margin-bottom: -10px;
  }

  .column-parent-1 .col-6 select {
    margin-top: -10px;
  }
}

@media screen and (max-width: 498px) {
  .card-body .row .column-parent-1 .parent-container {
    align-items: normal;
    flex-direction: column;
  }

  .column-parent-1 .input-container {
    margin-left: -130px !important;
  }

  .column-parent-1 .col-6 {
    left: 1%;
    margin-bottom: -10px;
  }

  .column-parent-1 .col-6 select {
    margin-top: -10px;
  }
}

@media screen and (max-width: 394px) {
  .card-body .row .column-parent-1 .parent-container {
    align-items: normal;
    flex-direction: column;
  }

  .column-parent-1 .input-container {
    margin-left: -130px !important;
  }

  .column-parent-1 .col-6 {
    left: -7%;
    margin-bottom: -10px;
  }

  .column-parent-1 .col-6 select {
    margin-top: -10px;
  }
}
