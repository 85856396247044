td.action-btn-container {
  vertical-align: middle;
}

.action-btn-container .action-btns {
  font-size: 0.75rem;
  line-height: 1.25;
  padding: 6px 10px;
  border-radius: 0.25rem;
}

@media only screen and (max-width: 912px) {
  .mobile-d-1 {
    display: none;
  }
}

@media only screen and (max-width: 790px) {
  .mobile-d-2 {
    display: none;
  }
}

@media only screen and (max-width: 660px) {
  .mobile-d-3 {
    display: none;
  }
}

@media only screen and (max-width: 530px) {
  .mobile-d-4 {
    display: none;
  }
}

@media only screen and (max-width: 400px) {
  .mobile-d-5 {
    display: none;
  }
}


