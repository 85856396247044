.card-title {
  color: black !important;
}

#row1 {
  align-items: normal !important;
}

#row1 label {
  margin-left: -1px;
  margin-top: 10px !important;
}

.col-sm-12 .card.upperCard div.card-body {
  padding-top: 0;
  padding-left: 0;
  padding-right: 0;
}
