td.action-btn-container {
  vertical-align: middle;
}

.action-btn-container .action-btns {
  font-size: 0.75rem;
  line-height: 1.25;
  padding: 6px 10px;
  border-radius: 0.25rem;
}

#limit-update1 {
  min-width: 125px !important;
}

@media screen and (max-width: 768px) {
  .card-body .row .column-parent-2 .parent-container {
    align-items: normal;
    flex-direction: column;
  }

  .column-parent-2 .input-container {
    margin-left: -180px !important;
  }

  .column-parent-2 .col-6 {
    left: 5%;
    margin-bottom: -10px;
  }

  .column-parent-2 .col-6 select {
    margin-top: -10px;
  }
}

@media screen and (max-width: 610px) {
  .card-body .row .column-parent-1 .parent-container {
    align-items: normal;
    flex-direction: column;
  }

  .column-parent-2 .input-container {
    margin-left: -180px !important;
  }

  .column-parent-2 .col-6 {
    left: 0%;
    margin-bottom: -10px;
  }

  .column-parent-2 .col-6 select {
    margin-top: -10px;
  }
}

@media screen and (max-width: 498px) {
  .card-body .row .column-parent-2 .parent-container {
    align-items: normal;
    flex-direction: column;
  }

  .column-parent-2 .input-container {
    margin-left: -180px !important;
  }

  .column-parent-2 .col-6 {
    left: -5%;
    margin-bottom: -10px;
  }

  .column-parent-2 .col-6 select {
    margin-top: -10px;
  }
}

@media screen and (max-width: 394px) {
  .card-body .row .column-parent-2 .parent-container {
    align-items: normal;
    flex-direction: column;
  }

  .column-parent-2 .input-container {
    margin-left: -180px !important;
  }

  .column-parent-2 .col-6 {
    left: -10%;
    margin-bottom: -10px;
  }

  .column-parent-2 .col-6 select {
    margin-top: -10px;
  }
}
