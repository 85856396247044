h3 .card-title {
  color: white !important;
}

.main-one th {
  height: 50px !important;
  vertical-align: middle !important;
}

.main-one td {
  height: 50px !important;
  vertical-align: middle !important;
}

.first-eight {
  width: 10% !important;
  min-width: 10% !important;
}

.first-nine {
  width: 7% !important;
  min-width: 7% !important;
}

.first-seven {
  width: 7% !important;
  min-width: 7% !important;
}

.first-two {
  width: 4% !important;
  min-width: 4% !important;
}

.first-one {
  width: 3% !important;
  min-width: 3% !important;
}

.first-three {
  width: 8% !important;
  min-width: 8% !important;
}

.first-six {
  width: 9% !important;
  min-width: 9% !important;
}

.basic-single {
  color: black !important;
}
